<template>
  <div class="showcase-offers">
    <h2>
      <a :href="showcase.link" target="_blank">{{ showcase.title }}</a>
    </h2>
    <v-container class="grey lighten-5">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <v-data-iterator :items="showcase.offers" item-key="id">
            <template v-slot:default="{ items }">
              <v-card v-for="item in items" :key="item.id" class="ma-5">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-img
                    max-width="100px"
                    max-height="50px"
                    contain
                    :src="item.logo"
                  >
                  </v-img>

                  <v-card-title>{{ item.title }} </v-card-title>
                  <v-card-actions>
                    <v-btn @click="onClickAdd(item)" elevation="1">
                      добавить
                      <v-icon right> mdi-chevron-right </v-icon>
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </template>
          </v-data-iterator>
        </v-col>
        <v-col cols="12" sm="6">
          <v-data-iterator
            ref="listItem"
            :items="showcase.project_offers"
            item-key="id"
          >
            <template v-slot:default="{ items }">
              <v-card
                v-for="item in items"
                :key="item.id"
                class="ma-5"
                elevation="2"
                outlined
              >
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-icon class="rowHandle">mdi-sort</v-icon>

                  <v-img
                    class="white--text align-end"
                    max-width="100px"
                    max-height="50px"
                    contain
                    :src="item.offer.logo"
                  >
                  </v-img>
                  <v-card-title>
                    {{ item.offer.title }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="onClickDel(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn :to="'/offers/' + item.offer.id" icon>
                      <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>
                    <v-btn icon @click="onOpenItems(item.id)">
                      <v-icon>{{
                        openItems[item.id]
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      }}</v-icon>
                    </v-btn>
                  </v-card-actions>
                </div>
                <v-expand-transition>
                  <div v-show="openItems[item.id]">
                    <v-divider></v-divider>

                    <v-card-text>
                      <div>{{ item.offer.header }}</div>
                      <div>{{ item.offer.subHeader }}</div>
                      <div>Вероятность одобрения: {{ item.offer.chance }}</div>
                      <div>
                        <a :href="item.link" target="_blank">{{ item.link }}</a>
                      </div>
                      <div>Offer ID: {{ item.offer_id }}</div>
                      <v-checkbox
                        v-model="item.is_featured"
                        label="Спец предложение"
                        @click="onClickIsFeatured(item)"
                      ></v-checkbox>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import { createNamespacedHelpers } from "vuex";

const { mapActions, mapGetters } = createNamespacedHelpers("showcaseOffers");

export default {
  name: "showcaseOffers",
  data() {
    return {
      showcasesRoute: { name: "showcases" },
      openItems: {},
    };
  },
  computed: {
    ...mapGetters(["showcase"]),
  },
  mounted() {
    const listItem = this.$refs.listItem.$el;
    Sortable.create(listItem, {
      handle: ".rowHandle",
      onEnd: this.sortOffers,
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch("showcaseOffers/get", to.params.id).then(() => {
      next();
    });
  },
  methods: {
    ...mapActions([
      "get",
      "pushOffers",
      "popOffers",
      "sortOffers",
      "chnageIsFeaturedOffer",
    ]),
    save() {
      this.update(this.formData);
      this.$router.push(this.showcasesRoute);
    },
    onOpenItems(id) {
      this.openItems[id] = !this.openItems[id];
      this.openItems = { ...this.openItems };
    },
    onClickAdd(item) {
      this.pushOffers(item);
    },
    onClickDel(item) {
      this.popOffers(item);
    },
    onClickIsFeatured(item) {
      this.chnageIsFeaturedOffer({
        id: item.id,
        is_featured: item.is_featured,
      });
    },
  },
};
</script>
